@tailwind base;
@tailwind components;

.loader {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}

.loader:after {
  content: " ";
  display: block;
  width: 24px;
  height: 24px;
  margin: 1px;
  border-radius: 50%;
  border: 4px solid #facc15; /* Changed border color to #facc15 */
  border-color: #facc15 transparent #facc15 transparent; /* Adjusted border colors */
  animation: loader 1.2s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@tailwind utilities;